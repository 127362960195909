/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.impact-generator-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  background-color: #f9f9f9;
}

.impact-info {
  max-width: 40%;
  padding: 20px;
}

.impact-info h1 {
  font-size: 28px;
  color: #333;
}

.impact-info p {
  margin: 10px 0;
  color: #666;
}

.impact-buttons {
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.demo-btn {
  background-color: #5c6bc0;
  color: white;
}

.paid-btn {
  background-color: #8e24aa;
  color: white;
}

.impact-image {
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.impact-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
