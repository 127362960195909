@font-face {
    font-family: 'Gotham';
    src: url('../../Fonts/Gotham-Font/GothamBook.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('../../Fonts/dm-sans-cufonfonts/DMSans-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Urbanist';
    src: url('../../Fonts/Urbanist-master/fonts/otf/Urbanist-Regular.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Evolventa';
    src: url('../../Fonts/evolventa-1.0/ttf/Evolventa-Regular.ttf');
}

$fontfamily: 'DM Sans';

p {
    font-family: $fontfamily !important;
    // font-family: 'DM Sans' !important;
}

span {
    font-family: $fontfamily !important;
    // font-family: "DM Sans" !important;

}

h1 {
    font-family: $fontfamily !important;
    // font-family: 'DM Sans' !important;
    font-weight: 700 !important;

}
// #careers-h1{
//     text-align: center;
//     font-size: 64px;
//     font-family: 'DM Sans';
//     font-weight: 900;
// }




h2 {
    font-family: $fontfamily !important;
    // font-family: 'DM Sans' !important;
    font-weight: 700 !important;
}

h3 {
    // font-family: 'DM Sans' !important;
    font-family: $fontfamily !important;
    font-weight: 700 !important;
}

h4 {
    font-family: $fontfamily !important;
    // font-family: 'DM Sans' !important;
    font-weight: 700 !important;
}

h5 {
    font-family: $fontfamily !important;
    // font-family: 'DM Sans' !important;
    font-weight: 700 !important;
}

a {
    font-family: $fontfamily !important;
    // font-family: 'DM Sans' !important;
}


.navbar-brand {
    content: url('../../Assets/Eraah-official-logo.png');
    display: inline-block;
    width: 7%;
    height: 30%;
    padding-top: 0px !important;
}

div.navbar-area {
    width: 100%;
    position: fixed;
    z-index: 999;
    background: transparent;
    box-shadow: 0px 4px 24.899999618530273px 0px rgba(0, 0, 0, 0.25);
    // height: 15dvh;
    top: 0
}

@media screen and (max-width:930px) {
    div.navbar-area {
        width: 100%;
       
        box-shadow: 0px 4px 24.899999618530273px 0px rgba(0, 0, 0, 0.25);
        height: 13%;
        top: 0
    }

}

.App {
    text-align: start;
}

.navbar-area {
    background-color: white !important;
}

div.navbar-area.scrolled {
    background: whitesmoke;
    transition: all 0.6s ease-in-out;
}

div.navbar-area.scrolled div.navItems>li {
    color: black;
}

div.navItems {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    position: absolute;
        left: 50%;
        transform: translateX(-50%);
}

div.navItems2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;

}

div.navItems>li {
    list-style: none;
    margin-right: 70px;
    // margin-right: 25px;
    color: #454545;
    font-size: 16px;
    font-weight: 400;

    a {
        font-family: $fontfamily !important;
        text-decoration: none;
        transition: color 0.3s ease;
        position: relative;
        
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -4px;
            width: 100%;
            height: 2px;
            background-color: #6100FF;
            transform: scaleX(0);
            transition: transform 0.3s ease;
            transform-origin: right;
        }
        
        &:hover {
            color: #6100FF;
            
            &:after {
                transform: scaleX(1);
                transform-origin: left;
            }
        }
    }

}

div.navItems2>li {
    list-style: none;
    margin-right: 70px;
    // margin-right: 25px;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;

    a {
        font-family: $fontfamily !important;
    }

}

.navItems .links.active {
    color: #6100FF;
    font-weight: 700;
}

.navItems .links.hover {
    color: #6100FF;
    
}

.navItems .btn.active {
    background-color: #512DA8;
    color: white;
}


@media screen and (max-width:1250px) {
    div.navItems>li {
        list-style: none;
        margin-right: 25px;
        color: rgba(0, 0, 0, 1);
        font-size: 17px;
        font-weight: 400;
    }
}

@media screen and (max-width:1040px) {
    div.navItems>li {
        list-style: none;
        margin-right: 25px;
        color: rgba(0, 0, 0, 1);
        font-size: 14px;
        font-weight: 400;
    }
}

// .navItems :hover{
//     color: rgba(234, 118, 0, 1);
//     font-weight: 500;
// }

// .navItems .links.active{
// color: #EA7600;
// font-weight: 700;
// }

// #dropdownMenu :hover{
//     color: rgb(255, 170, 0);
// }

div.navItems>li>a {
    text-decoration: none;
    color: inherit;
}

div.nav-toggler-icon {
    display: none;
    padding-right: 16px;
}

div.navbar-area div.nav-toggler-icon {
    font-size: 25px;
    cursor: pointer;
}

.navbar-area .btn {
    background-color: rgba(97, 0, 255, 1);
    // background-color: rgba(255, 195, 13, 1);

    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    font-family: $fontfamily;
    margin-right: 20px;
    box-shadow: 0px 1px 16.6px 0px rgba(97, 0, 255, 1);
}

div.navbar-area.scrolled div.nav-toggler-icon {
    color: black;
    font-size: 25px;
    cursor: pointer;
}

div.custom-sidebar {
    height: 120%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    // background: linear-gradient(93.3deg, #0961F7 0.97%, #6BA2FF 100%);
    // background: linear-gradient(#fa6f7d, #5282d3, #00d8e1, #9566bd);
    background: url('../../Assets/background-img.jpeg')
    ;
    // background: linear-gradient(rgba(0, 109, 119, 1),rgba(79, 118, 90, 1),rgba(237, 135, 32, 1));;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 30px;
}

div.close-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
    cursor: pointer;
}

div.close-btn-wrapper .close-icon {
    font-size: 30px;
    color: white;
}

.sidebar-list {
    list-style: none;
    padding-left: 0;
}

ul.sidebar-item-child {
    list-style: none;
}

ul.sidebar-item-child li a {
    font-size: 1.2rem;
}

.custom-sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: white;
    display: inline-block;
    transition: 0.3s;
}

.custom-sidebar a:hover {
    color: #f1f1f1;
}

.custom-sidebar.show {
    width: 100%;
}

@media only screen and (max-width: 991px) {
    div.navItems {
        display: none;
        position: absolute;
        padding-bottom: 90px;
        left: 0;
        flex-direction: column;
        width: 70%;
        background: whitesmoke;
        border: 1px solid red;
        max-height: 0;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
    }

    div.nav-toggler-icon {
        display: block;
    }

    div.navItems>li {
        display: block;
        width: 100%;
        text-align: center;
        color: black;
    }
}

@media only screen and (max-width: 991px) {
    img.navbar-brand-img {
        width: 70% !important;
    }

    .navbar-brand {
        width: 10% !important;
    }
}

@media only screen and (max-width: 576px) {
    .navbar-brand {
        width: 22% !important;
    }
}

.heading {
    font-family: $fontfamily;
    font-weight: 700;
    margin-left: 20px;
    margin-right: 20px;

    span {
        color: rgba(86, 40, 210, 1);
    }
}

.card {
    border: none;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    height: 100%;

}

.card img {
    width: 45%;
}

.white-circle {
    background-color: rgba(235, 234, 255, 1);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.card .card-body p {
    color: rgba(97, 97, 97, 1);
    font-family: $fontfamily;
    font-weight: 400;
}

.transparency {
    background-color: rgba(255, 237, 179, 1);
}

.trans-left {
    h2 {
        font-family: $fontfamily;
        font-weight: 700;
    }

    p {
        color: rgba(69, 69, 69, 1);
        font-weight: 400;
        font-size: 18px
    }

    .btn {
        background-color: rgba(97, 0, 255, 1);
    }
}

.circle img {
    width: 40%;
}

.circle {
    background-color: #ffc30d;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    align-items: center;
    display: flex;
    justify-content: center;
}

@media screen and (max-width:575px) {
    .trans-left {
        padding: 40px 20px 0px 20px !important;
    }
}

.involved {
    background: linear-gradient(93.3deg, #6100FF 0.97%, #7ED1F4 100%);

    border-radius: 16px;
}

.first {
    color: white;
    border: 2px solid white;
    border-radius: 114px;
    // width: 28%;
    // height: 30px;
    padding: 4px 4px;
    cursor: pointer;
}

@media screen and (min-width:1200px) {
    .involvedImage img {
        width: 85%;
        right: -22% !important;
    }

}

@media screen and (max-width:767px) {
    .first {
        width: 60%;
        margin-bottom: 12px !important;
    }

    .involvedImage {
        display: flex;
        margin: auto;
    }

    .involvedImage img {
        right: 25% !important;
        width: 140% !important;
    }

}

@media screen and (max-width: 450px) {
    .first {
        width: 74% !important;
    }

    .involvedImage img {
        right: 30% !important;
        width: 150% !important;
    }
}

@media screen and (max-width:380px) {
    .first {
        width: 80% !important;
    }

    .involvedImage img {
        right: 24% !important;
        width: 150% !important;
    }
}

@media screen and (min-width:300px) and (max-width:340px) {
    .involvedImage img {
        right: 19% !important;
        width: 150% !important;
    }

}

@media screen and (max-width:300px) {
    .involvedImage img {
        right: 15% !important;
        width: 150% !important;
    }

}

@media screen and (max-width: 340px) {
    .first {
        width: 84% !important;
        font-size: 11px !important;
    }

    // .involvedImage img{
    //     right: 14% !important;
    //     width: 150% !important;
    // } 
}

/*  FOOTER AREA  */

.footer-area {
    position: relative;
    z-index: 1;
    padding-top: 20px;
    background-color: rgba(26, 0, 51, 1);
    // background-color: rgba(97, 0, 255, 1);

    .logotext {
        margin-left: 10px;
        font-size: 40px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .list {
        li {
            margin-top: 10px;

            a {

                font-size: 16px;
                font-weight: 700;
            }
        }
    }
}

.single-footer-widget h4 {
    font-size: 24px;
    font-weight: 600;
    color: white;
    font-family: $fontfamily;
}

.single-footer-widget {
    margin-bottom: 30px;
}

.single-footer-widget h4 {
    margin-bottom: 24px;
    font-weight: 700;
    color: white;
    font-family: $fontfamily;
}

.single-footer-widget .list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    color: white;
    font-family: $fontfamily;
}

.single-footer-widget .list li {
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 14.5px;
    transition: margin 0.2s ease-in-out;
    font-family: $fontfamily;
}

.single-footer-widget .list li:hover {
    // margin-left: 4px;
}

.single-footer-widget .row a {
    color: white;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.2s ease-in;
    font-family: $fontfamily;
}

.single-footer-widget .list li a {
    color: white;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.2s ease-in;
    font-family: $fontfamily;
}

.copyright-area {
    color: white;
    font-family: $fontfamily;
}

.circleWhite {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    cursor: pointer;
    padding: 6px;
    background-color: rgba(97, 0, 255, 1);

    img,
    svg {
        vertical-align: unset;
    }
}

@media screen and (max-width:350px) {
    .footer {
        display: flex !important;
        justify-content: start !important;
    }

}

@media screen and (max-width:800px) {
    .office h4 {
        text-align: left !important;
    }

    .single-footer-widget .list {
        text-align: left !important;
    }

}

@media screen and (max-width:800px) {
    .footer {
        margin-left: 3rem;
        margin-right: 3rem;
    }

}

@media screen and (max-width:657px) {
    .copyright-area {
        display: flex;
        flex-direction: column !important;

        .policy-contentt {
            display: flex;
            flex-direction: column !important;
        }
    }

}

/* FIRST PART */

.first-heading {
    background-color: rgba(244, 244, 244, 1);
    margin-top: 150px;
    border-radius: 12px;
}

.first-part {
    .text {
        display: flex;
        flex-direction: column;
    }

    img {
        width: 20%;
    }
}

@media screen and (max-width:767px) {
    .first-part {
        img {
            width: 10% !important
        }

        .button {
            width: 50% !important;
            font-size: 12px !important;
        }
    }
}

@media screen and (max-width:350px) {
    .first-part {

        .button {
            width: 60% !important;
            font-size: 12px !important;
        }
    }

}

.button {
    display: flex;
    /* or grid */
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: rgba(97, 0, 255, 1);
    color: #fff;
    border: none;
    cursor: pointer;
    box-shadow: 0px 0px 23.600000381469727px 0px rgba(86, 40, 210, 1);
    border-radius: 6px;
    width: 65%;

}

.button-text {
    margin-right: 5px;
    /* Adjust as needed */
}

.laxmi {
    width: 100%;
}

.students p {
    color: rgba(167, 167, 167, 1);
}

.images img {
    width: 25% !important;
    padding: 6px !important;
}

.boost img {
    width: 45%;
    display: flex;
    justify-content: center;
}

.insta {
    width: 25%;
    padding: auto;
}

.form-control {
    border-radius: 20px;
    background-color: rgba(244, 244, 244, 1);
    width: 66%;
}

.copy-link {
    cursor: pointer;

    img {
        width: 25%;
    }
}

.pointer:hover {
    cursor: pointer;
    color: #512DA8;
    font-weight: 700;

}

.sign-in img:hover {
    cursor: pointer;
}

.boost {
    border-radius: 16px;
    padding: 50px 25px 35px 30px;
    border: 1px solid rgba(217, 217, 217, 1);
}

@media screen and (max-width:376px) {
    .insta p {
        font-size: 12px !important;
    }

    .copy-link span {
        font-size: 12px !important;
    }

    .boost {
        padding: 40px 15px 25px 15px;
    }
}

.sign-in img {
    width: 10%;
}

.sign-in {
    box-shadow: 0px 0px 8.600000381469727px 0px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    margin-top: 25px;
    padding: 30px 30px 30px 30px;
}

.login {
    border-radius: 48px;
    border: 1px solid rgba(97, 0, 255, 1);
    color: rgba(97, 0, 255, 1);
    // margin-bottom: 10px;
}

.login:hover {
    background-color: #512DA8 !important;
}

.create:hover {
    background-color: #512DA8 !important;
}

.create {
    border-radius: 48px;
    border: 1px solid rgba(97, 0, 255, 1);
    background-color: rgba(97, 0, 255, 1);
    color: white;
}

.email {
    width: 100%;
}

.map {
    position: relative;
}

.map img {
    width: 100%;
    background-image: url('../../Assets/map.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 45dvh;
    // position: relative;
}

.map p {
    font-family: $fontfamily;
    font-size: 24px;
    font-weight: 400;
    // line-height: 33px;
    letter-spacing: 0em;
    text-align: left;

}

.map .btn-primary {
    background-color: rgba(97, 0, 255, 1);
}

.content h1 {
    font-size: 20px;
}

.content p {
    font-size: 16px;
}

.map .content {
    top: 25%;
    right: 10%;
    position: absolute;
}

.map .image .location {
    position: absolute;
    top: 14%;
    width: 10%;
    height: 34%;
    left: 16%;
    background: none;
}

@media screen and (max-width: 991px) {
    .map .content {
        top: 25%;
        right: 0%;
        position: absolute;
    }

    .map h1 {
        font-size: 14px;
    }

    .map p {
        font-size: 12px !important;
    }

}

@media screen and (max-width:712px) {
    .map .image .location {
        width: 12%;
        height: 28%;
    }
}

@media screen and (max-width: 500px) {
    .map .content {
        padding: 12px;
        top: 50%;
        right: 16%;
        position: absolute;
    }

}

@media screen and (max-width: 350px) {
    .map .image .location {
        width: 12% !important;
        height: 20% !important;
    }

}

.proof {
    background-color: rgba(244, 244, 244, 1);
    border-radius: 12px;
    padding: 30px;
}

.proof-image img {
    display: flex;
    justify-content: center;
    margin: auto;
}

.banner img {
    // height: 98dvh;
    position: relative;
}

.banner .filterimage {
    transition: filter .3s ease-in;
    filter: brightness(0.5);
}

.banner-content {
    position: absolute;
    // top: 33%;
    top: 40%;

    h1 {

        font-size: 80px;
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
    }

    span {
        color: rgba(255, 195, 13, 1);
    }

    p {
        color: rgba(255, 255, 255, 1);
        font-weight: 400;
        font-size: 30px;

    }

    .button {
        width: 20%;
        border-radius: 10px;
        box-shadow: none !important;
    }

    .button .button-text {
        color: white;
    }
}

@media screen and (max-width: 820px) {
    .banner-content {

        h1 {
            font-size: 30px !important;
        }

        p {
            font-size: 12px !important;
        }

        .button {
            width: 25%;
            box-shadow: none !important;
        }
    }

}

// @media screen and (max-width: 624px) {
//     .banner-content {
//         position: absolute;
//         top: 33%;
//         left: 9%;
//         width: 60%;

//         h1 {
//             font-size: 20px !important;
//         }

//         p {
//             font-size: 10px !important;
//         }

//         .button {
//             font-size: 12px !important;
//             width: 44%;
//             border-radius: 45px;
//             box-shadow: none !important;
//         }

//         img {
//             width: 16% !important
//         }
//     }

// }

@media screen and (max-width: 490px) {
    .banner-content {
        position: absolute;
        top: 20%;
       

        h1 {
            font-size: 20px !important;
        }

        p {
            font-size: 10px !important;
        }

        .button {
            width: 40% !important;
            font-size: 10px !important;
            border-radius: 10px;
            box-shadow: none !important;
            padding: 6px !important;
        }
    }

}

// @media screen and (max-width: 410px) {
//     .banner-content {
//         position: absolute;
//         top: 15%;
//         left: 2%;
//         width: 60%;

//         h1 {
//             font-size: 14px !important;
//         }

//         p {
//             font-size: 8px !important;
//             margin-bottom: 8px !important;
//         }

//         .button {
//             font-size: 10px !important;
//             width: fit-content;
//             border-radius: 45px;
//             box-shadow: none !important;
//             padding: 8px !important;
//         }

//         img {
//             width: 16% !important
//         }
//     }

// }

// @media screen and (max-width:260px) {
//     .banner-content {
//         position: absolute;
//         top: 13% !important;

//         .button {
//             font-size: 8px !important;
//             width: fit-content;
//             border-radius: 45px;
//             box-shadow: none !important;
//             padding: 8px !important;
//         }
//     }

// }



.support-teachers {
    display: flex;
    justify-content: center;

    h2 {
        font-family: $fontfamily;
        font-weight: 700;
    }

    span {
        color: rgba(97, 0, 255, 1);
    }

    input {
        border: none;
        box-shadow: 0px 4px 4px 0px rgba(130, 134, 157, 0.08);
        height: 50px;
        border-radius: 57px;
    }
}

.all {
    border-radius: 32px;
    background-color: rgba(244, 244, 244, 1);
    margin: 5px;
    align-items: center;
    justify-content: center;
    display: flex;

    span {
        color: rgba(97, 97, 97, 1);
    }
}

@media screen and (max-width: 576px) {
    .all {
        flex: 0 0 auto;
        width: 25%;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .Eraahfooter-logo{
        width: 20% !important;
    }
}

.search-tags {
    align-items: center;
    justify-content: center;
    display: flex;

    .all {
        span {
            padding: 6px 15px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.paginationCard {
    padding: 0px !important;
    width: 30% !important;

    img {
        width: 100%;
    }

    .childrens {
        padding: 16px 16px 0px 16px;
        display: flex;
        justify-content: space-between;

        span {
            color: rgba(153, 156, 169, 1);
        }

        .count {
            color: rgba(255, 195, 13, 1);
        }

        img {
            width: 20%;
        }

        .purple {
            background-color: rgba(97, 0, 255, 1);
            border-radius: 8px;
        }
    }

    .childrenss {
        img {
            width: 13% !important
        }

        .purple {
            background-color: #6100FF;
        }
    }

    .borderRadius {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
}

.bloggCarddd {
    height: 62dvh !important;
    // width: 60%;
}

@media screen and (max-width:575px) {
    .bloggCarddd {
        width: 68% !important;
        height: 72dvh !important;
    }

}

@media screen and (max-width:991px) {
    .paginationCard {
        .childrenss img {
            width: 20% !important;
        }
    }

}

@media screen and (max-width:450px) {
    .paginationCard {
        width: 70% !important
    }

}

@media screen and (max-width:575px) {
    .paginationCard .childrens img {
        width: 9% !important;
    }

}

.paginationLatest {
    padding: 0px !important;

    img {
        width: 100%;
    }

    .childrensPost {
        padding: 16px 16px 0px 16px;
        display: flex;
        justify-content: space-between;

        span {
            color: rgba(0, 0, 0, 1);
            font-family: $fontfamily;
        }

        .count {
            color: rgba(147, 148, 147, 1);
            font-family: $fontfamily;
            font-weight: 400;
        }

        img {
            width: 20%;
        }

    }

    .card-body {
        color: rgba(123, 125, 140, 1);
        font-family: $fontfamily;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        .purple {
            color: rgba(86, 40, 210, 1) !important;
            text-decoration: none;
            font-family: $fontfamily;
            font-weight: 500;
            cursor: pointer;
        }

    }
}

.app {
    font-family: $fontfamily;

    p {
        color: rgba(9, 97, 247, 1);
        font-weight: 500;
    }

    h2 {
        font-family: $fontfamily;
        font-weight: 700;
    }

    span {
        font-family: $fontfamily !important;
        color: rgba(86, 40, 210, 1);
    }

    img {
        width: 100%;
    }

}

.data {
    background-color: rgba(235, 234, 255, 1);
    border-radius: 11px;
    padding: 20px;

    img {
        width: 100%;
    }
}

.data-protected {
    h5 {
        font-family: $fontfamily;
        color: rgba(97, 97, 97, 1);
        font-weight: 600;
    }

    span {
        color: rgba(123, 125, 140, 1);
        font-size: 16px;
    }
}

@media screen and (max-width:373px) {
    .data {
        padding: 8px;
    }

}

.phoneImages {
    .googlePlayy {
        object-fit: cover;
        // margin-top: 20px;
        // width: 30%;
        // height: 50%;
    }

    .appStore {
        object-fit: cover;
    }

    // width: 40%;

}


// DONATE



.donate {
    margin-top: 140px;

    input {
        border: none;
        box-shadow: 0px 4px 4px 0px rgba(130, 134, 157, 0.08);
        height: 50px;
        border-radius: 57px;
    }

    .kidContainer {
        width: 44%;
        background-color: white;
        padding: 40px;
        border-radius: 24px;
        position: absolute;
        top: 70%;
        left: 6%;
        box-shadow: 0px 12px 24px -6px rgba(24, 26, 42, 0.12);

    }

    button {
        background-color: rgba(86, 40, 210, 1);
        border-radius: 6px;
    }


    .calender p {
        color: rgba(147, 148, 147, 1);
        font-family: $fontfamily;
        font-weight: 400
    }
}

@media screen and (max-width:924px) {
    .donate {
        margin-top: 90px !important;

        .searchBar input {
            font-size: 14px !important;
            border-radius: 40px !important;
        }
    }

}

@media screen and (max-width:991px) {
    .kidContainer {
        padding: 20px !important;

        h2 {
            font-size: 15px;
        }
    }
}

@media screen and (max-width:767px) {
    .kidContainer {
        width: 50% !important;
    }

}

@media screen and (max-width:510px) {
    .kidContainer {
        position: absolute;
        top: 50% !important;

        button {
            font-size: 11px;
        }

        h2 {
            font-size: 12px !important;
        }

        p {
            font-size: 10px;
        }
    }
}

@media screen and (max-width:385px) {
    .kidContainer {
        position: absolute;
        top: 30% !important;
        padding: 12px !important;

        button {
            font-size: 9px;
        }

        h2 {
            font-size: 10px !important;
        }

        p {
            font-size: 9px;
        }
    }
}

// CAROUSAL

@import url('https://fonts.googleapis.com/css?family=Nunito');

$default-height: 80%;
$default-width: 25%;
$level2-height: $default-height - 8%;
$level2-width: $default-width - 8%;
$level1-height: $default-height - 2%;
$level1-width: $default-width - 2%;
$level0-height: $default-height + 5%;
$level0-width: $default-width + 5%;
$level-2-left: 15%;
$level-1-left: 30%;
$level0-left: 50%;
$level1-left: 70%;
$level2-left: 85%;
$level-2-bs: -.1em .1em .2em #212121;
$level-1-bs: -.2em .2em .4em #212121;
$level2-bs: .1em .1em .2em #212121;
$level1-bs: .2em .2em .4em #212121;
$level0-bs: 0 .4em .8em #212121;

body {
    // padding-top: 1em;
    // background-color: #673AB7;
    // font-family: 'Nunito';
}

.basedOn {
    text-align: center;
    font-size: 1.2em;
    color: #FFF;

    a,
    a:visited,
    a:hover,
    a:active {
        color: #FFEB3B;
    }
}

#carousel {
    height: 100%;
    width: 100%;
    margin: auto;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
}

.arrow {
    position: absolute;
    width: 60px;
    height: 100%;
    background-color: #673AB7;
    text-align: center;
    font-size: 2em;
    cursor: pointer;
    line-height: 30px;
    z-index: 1000;
    // top: 50%;
    transform: translate(0, -50%);

    i {
        //   transform: translate(-50%, -50%);
        //   top: 50%;
        //   left: 50%;
        //   position: absolute;
    }

    transition: all 500ms;

    &-right {
        right: 0;
    }

    &-left {
        left: 0;
    }

    &:hover {
        background-color: #512DA8;
        color: #FFEB3B;
    }
}

.item {
    text-align: center;
    color: white;
    font-size: 40px;
    position: absolute;
    // left: 50%;
    // top: 50%;
    transform: translate(-50%, -50%);
    transition: box-shadow 1s, height 250ms, width 250ms, left 1s, margin-top 1s, line-height 250ms, background-color 1s;
}

.level-2 {
    box-shadow: $level-2-bs;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    background-color: #228291;
    left: $level-2-left;
}

.level-1 {
    box-shadow: $level-1-bs;
    height: $level1-height;
    width: $level1-width;
    line-height: $level1-height;
    background-color: #6796E5;
    left: $level-1-left;
}

.level0 {
    box-shadow: $level0-bs;
    height: $level0-height;
    width: $level0-width;
    line-height: $level0-height;
    background-color: #4EC9E1;
    left: $level0-left;
}

.level1 {
    box-shadow: $level1-bs;
    height: $level1-height;
    width: $level1-width;
    line-height: $level1-height;
    background-color: #6796E5;
    left: $level1-left;
}

.level2 {
    box-shadow: $level2-bs;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    background-color: #228291;
    left: $level2-left;
}

.level-2,
.level2 {
    z-index: 0;
}

.level-1,
.level1 {
    z-index: 1;
}

.level0 {
    z-index: 2;
}

.left-enter {
    opacity: 0;
    left: $level2-left;
    height: $level2-height - $default-height;
    width: $level2-width - $default-width;
    line-height: $level2-height - 30;

    &.left-enter-active {
        opacity: 1;
        left: $level2-left;
        height: $level2-height;
        width: $level2-width;
        line-height: $level2-height;
        transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.left-leave {
    opacity: 1;
    left: $level-2-left;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    // z-index: -1;

    &.left-leave-active {
        // z-index: -1;
        left: $level-2-left;
        opacity: 0;
        height: $level2-height - $default-height;
        width: $level2-width - $default-width;
        line-height: 120px;
        transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.right-enter {
    opacity: 0;
    left: $level-2-left;
    height: $level2-height - $default-height;
    width: $level2-width - $default-width;
    line-height: $level2-height - 30;

    &.right-enter-active {
        left: $level-2-left;
        opacity: 1;
        height: $level2-height;
        line-height: $level2-height;
        width: $level2-width;
        transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.right-leave {
    left: $level2-left;
    height: $level2-height;
    opacity: 1;
    line-height: $level2-height;
    width: $level2-width;

    &.right-leave-active {
        left: $level2-left;
        opacity: 0;
        height: $level2-height - $default-height;
        width: $level2-width - $default-width;
        line-height: $level2-height - 30;
        transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.noselect {
    -webkit-user-select: none;
    -html-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#carousel {
    /* Set carousel position to static or relative */
    /* Adjust top or margin-top property as needed */
    position: relative;
    // top: 0;
    // margin-top: 20px; /* Adjust the margin-top to create space between the donate container and the carousel */
}

.categories .card {
    background: linear-gradient(180deg, #C4D9EE 0%, rgba(78, 120, 162, 0) 100%);
    border: 1px solid rgba(189, 178, 178, 1);
    border-radius: 9px;
    box-shadow: none !important;

    .card-body {
        padding: 0.5rem 0.5rem
    }

}

.categories {
    .animal {
        background: linear-gradient(180deg, #CFC2B7 0%, rgba(68, 52, 39, 0.16) 100%);
    }

    .environment {}
}

.articleForm {
    padding: 60px 100px 0px 100px;
    //  background: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3) 100%);
    margin: 30px 60px 0px 60px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);

}

@media screen and (max-width:767px) {
    .articles img {
        width: 70% !important;
        display: flex;
        margin: auto;
    }

}

@media screen and (max-width:600px) {
    .articleForm {
        padding: 0px !important;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3) 100%);
        margin: 50px;
    }

}

@media screen and (max-width:400px) {
    .articleForm input {
        font-size: 11px !important;
    }

}

// MISSION

// .missionPage {
//     margin-top: 90px;

//     .missionKid {
//         width: 50%;
//     }

//     .mission {
//         padding: 25px 0px 15px 0px;
//         background-color: #EFF2F2;
//         border-radius: 6px;

//         p {
//             color: #3A170080;
//         }
//     }

//     .volunteer {
//         background-color: #5628D2;
//         border-radius: 15px;
//         height: 170px;
//     }

//     .upperContainer {
//         position: absolute;
//         top: 0%;
//         left: 18%;
//     }
// }

// @media screen and (max-width: 1150px) {
//     .missionPage .upperContainer {
//         position: absolute;
//         top: 0%;
//         left: 12%;
//     }

// }

// @media screen and (max-width: 800px) {
//     .missionPage .upperContainer {
//         position: absolute;
//         top: 0%;
//         left: 0%;
//     }

//     .mission img {
//         width: 32% !important;
//     }

//     .hurrayImage {
//         height: 86dvh !important;
//     }


// }

// @media screen and (max-width: 519px) {
//     .missionPage .upperContainer {
//         position: absolute;
//         top: 0%;
//         left: 0%;
//     }

//     .hurrayImage {
//         height: 78dvh !important;
//     }

//     .volunteer {
//         width: 58% !important;
//     }
// }

// @media screen and (max-width: 450px) {
//     .volunteer {
//         width: 58% !important;

//         h3,
//         h5 {
//             font-size: 20px;
//         }

//         img {
//             width: 20%;
//         }

//         .volContent {
//             // padding-top: 20px !important;
//         }
//     }

// }

// @media screen and (max-width:400px) {
//     .mission {
//         img {
//             padding-left: 8px !important;
//             padding-right: 8px !important;
//         }

//         h2 {
//             padding-left: 4px !important;
//             font-size: 16px !important;
//         }

//         p {
//             font-size: 11px !important;
//         }
//     }

//     .volunteer {
//         height: 140px !important;

//         h3,
//         h5 {
//             font-size: 14px !important;
//         }

//     }



// }








.supporters {
    box-shadow: 0px 11.467570304870605px 28.668922424316406px 0px #21343014;

    h3 {
        color: #5628D2;
        font-family: $fontfamily;
        font-weight: 700;
    }

    p {
        color: #213430;
        font-family: $fontfamily;
        font-weight: 300;

    }
}


.causes {
    h1 {
        color: #FFC30D;
        font-family: $fontfamily;
        font-weight: 700;
    }

    .water {
        color: white;
        background-color: #5628D2;
        border-radius: 10px;
        width: 34%;
    }

    .progress {
        height: 0.5rem !important;

        .progress-bar {
            background-color: #5628D2;
        }
    }

    .donationButton {
        color: white !important;
        background-color: #5628D2;
        border-radius: 18px;
    }

    .explore {
        color: #FFC30D;
        border: 1.15px solid #7E8AB84E;
        border-radius: 12px;
        width: fit-content;
    }
}

// PARTNER



.trust {
    position: relative;
}

.trust .yellowLeftRight {
    position: absolute;
    top: 10%;
}

.trust .yellowRightLeft {
    position: absolute;
    right: 0%;
    top: 10%;
}

@media screen and (max-width:450px) {
    .trusttt {
        flex-direction: column;
        align-items: center;

        .founder {
            width: 60% !important;

            img {
                width: 80% !important;
            }
        }

        .why {
            margin-left: 0px !important;
        }
    }

}

.partner {
    margin-top: 150px;

    h1,
    h2 {
        color: #5628D2;
        font-family: $fontfamily;
        font-weight: 800;

    }

    .partnerImage {
        background-image: url('../../Assets/partnerBackground.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 40%;
    }

}

.why {
    h5 {
        color: #575757;
    }
}

.grow {
    margin: auto;
    padding: 50px;
    background-color: white;
    border-radius: 8px;
    width: 77%;

    p,
    span,
    label {
        color: #2B2B2B;
        font-weight: 700;
        font-size: 12px;
    }
}

.grow form input,
textarea {
    background-color: white !important;
    border-radius: 8px;
    width: 100%;
}

@media screen and (max-width:680px) {
    .success {
        width: 100%;
    }

    .grow {
        padding: 20px;
    }

}

// GALLERY

.gallery {
    margin-top: 120px;
    position: relative;

    h1 {
        font-family: $fontfamily;
        font-weight: 700;
        text-align: center;
        color: #5628D2;
    }

    .blur {
        position: absolute;
        top: -7%;
        width: 14%;
    }

    .smiles {
        position: absolute;
        color: #5628D2;
        background-color: #D9D9D9;
        border-radius: 0px 0px 39px 39px;
        top: 0%;
        left: 40%;
        font-size: 20px;
        font-weight: 800;
        font-family: $fontfamily;
        right: 40%;
        padding: 10px 35px 10px 35px;
    }
}

@media screen and (max-width:360px) {
    video {
        width: 200px !important;
    }

}

@media screen and (max-width:984px) {
    .smiles {
        padding: 10px 20px 10px 20px !important;
        // font-size: 16px !important;
    }

}

@media screen and (max-width:550px) {
    .smiles {
        position: absolute;
        // padding: 10px 60px 10px 60px !important;
        font-size: 14px !important;
        // width: 40%;
        right: 30% !important;
        left: 30% !important;
    }
}

@media screen and (max-width:318px) {
    .smiles {
        position: absolute;
        right: 29% !important;
        left: 20% !important;
    }

}

.helppUS {
    // line-height: 76.71px;
    color: #3A170080;
    font-family: $fontfamily;
    font-weight: 400;
    font-size: 18px;
}

.chotu1Con {
    position: relative;

    h5 {
        position: absolute;
        bottom: 1%;
        left: 15%;
    }
}

@media screen and (max-width:400px) {
    .chotu1Con h5 {
        font-size: 15px !important;
    }

}

// bankPartner

.bankPartner {

    // margin-top: 150px;
    img {
        background-image: url('../../Assets/becomePartner.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.bankBanner {
    position: relative;

    .bankBannerContent {
        position: absolute;
        top: 50%;
        right: 36%;
    }
}

@media screen and (max-width:950px) {
    .bankBannerContent {
        position: absolute;
        top: 32% !important;
        right: 30% !important;
    }
}

@media screen and (max-width:710px) {
    .partnerButton {
        margin-top: .6rem !important;
        padding: 8px 22px !important;
    }
}

@media screen and (max-width:570px) {
    .partnerText {
        font-size: 24px !important;
    }

    .bankBannerContent {
        position: absolute;
        top: 23% !important;
        right: 29% !important;
    }

    .partnerButton {
        margin-top: .6rem !important;
        padding: 2px 18px !important;
    }
}

@media screen and (max-width:400px) {
    .partnerText {
        font-size: 22px !important;
    }

    .bankBannerContent {
        position: absolute;
        top: 23% !important;
        right: 23% !important;
    }

    .partnerButton {
        margin-top: .6rem !important;
        padding: 2px 18px !important;
        font-size: 14px;
    }
}

@media screen and (max-width:323px) {
    .partnerText {
        font-size: 18px !important;
    }

    .bankBannerContent {
        position: absolute;
        top: 18% !important;
        right: 23% !important;
    }

    .partnerButton {
        margin-top: .2rem !important;
        padding: 2px 10px !important;
        font-size: 14px;
    }

}

@media screen and (max-width:575px) {
    .paginationCard {
        // margin: 20px;
    }

}

.conditions {
    background-image: url('../../Assets/t&cBackground.png');
    background-repeat: no-repeat;
    margin-top: 120px;

    h1 {
        color: #5628D2;
        font-family: $fontfamily;
        font-weight: 700;
    }

    p {
        font-size: 12px;
    }

    h5 {
        color: #5628D2;
        font-family: $fontfamily;
        font-weight: 700;
    }

    .scope {
        border: 1.22px solid #E3E3E3;
        padding: 12px;
        margin-top: 14px;

    }
}

.conditionsRight {
    padding: 0px 20px 0px 20px
}

@media screen and (max-width:991px) {
    .conditions img {
        width: 70% !important;
        margin: auto;
        display: flex;
    }

}

.team {
    margin-top: 140px;

    .teamBanner img {
        background-image: url('../../Assets/teamBackground.png');
        background-repeat: no-repeat;
    }

    .teamContent {
        width: 60%;
    }
}

.team-container {
    position: relative;

    .teamContent {
        position: absolute;
        top: 0%;
        right: 21%;

        img {
            width: 32%;
        }

        p {
            font-size: 12px;
        }
    }
}

@media screen and (max-width:887px) {
    .teamBanner img {
        height: 250px;
    }

    .teamContent {
        p {
            font-size: 10px;
        }

        .donationButton {
            margin-top: 0.6rem !important;
            font-size: 10px;
        }
    }

}

@media screen and (max-width:450px) {
    .teamContent {
        width: 80% !important;
        right: 11% !important;

        p {
            font-size: 10px;
        }

        img {
            width: 42% !important;

        }
    }

}

.work-container {
    .work {
        background-color: #E7F2FF;
        padding: 8px;
        width: 30%;
    }

    h5 {
        color: #5628D2;
    }
}

@media screen and (max-width:450px) {
    .work-container {
        width: 90% !important;
    }

}

.awesome {
    h3 {
        color: #5628D2;
        font-family: $fontfamily;
        // font-family: 800;
    }
}

@media screen and (max-width:767px) {
    .awesome {
        padding: 40px;
    }

}

.openings {
    background-color: #F9F9F9;

    h3 {
        color: #5628D2;
        font-family: $fontfamily;
        font-weight: 700;
    }

    p {
        color: #212121;
        font-weight: 400;
        font-family: $fontfamily;
    }

    .developer {
        background-color: white;
        box-shadow: 0px 1.5px 3px 0px #0000000D;
        padding: 10px 8px 8px 8px;
        border-radius: 2.25px;

        span {
            color: #D4D4D4;
            font-family: $fontfamily;
            font-weight: 400;
        }

        p {
            color: #001833;
            font-family: $fontfamily;
            font-weight: 700;
        }

    }

    .developerOpenings img {
        width: 6%;
        height: 12px;
        display: flex;
        margin: auto;
    }
}

@media screen and (max-width:480px) {
    .developer {

        h5 {
            font-size: 14px;
            width: 30% !important;
            padding-left: 0px !important;
        }

        span {
            font-size: 10px;
        }

        p {
            font-size: 12px;
        }

        .Deadline {
            padding-left: 8px !important;
            padding-right: 8px !important;
        }

        .experience {
            padding-left: 8px !important;
            padding-right: 8px !important;
        }
    }

}

.faq {
    margin-top: 150px !important;

    h1 {
        color: #5628D2;
        font-family: $fontfamily;
        font-weight: 700;
    }
}

.accordion-item {
    border: none !important;
}

.accordion-button {
    background-color: #FFC30D08;
    color: #333333 !important;
    font-weight: 700 !important;
    font-family: $fontfamily !important;

}

.accordion-button:not(.collapsed) {
    color: #333333;
    background-color: #FFC30D08;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.faq a {
    cursor: pointer;
    color: #ffc30d;
    font-family: $fontfamily;
    font-weight: 700;
}

.faq a:hover {
    color: #512DA8;

}

.log-in {
    margin-top: 80px !important;

    .rightt {
        // margin-top: 100px !important;
    }

    h5 {
        color: #616161;
        font-family: $fontfamily;
        font-weight: 700;
    }

    .face {
        background-color: #F2F6F9;
        padding: 2px 4px;
        display: flex;
        align-items: center;
        margin: 8px;
    }

    .twit .button {
        box-shadow: none;
        background-color: #5628D2;
        // color: white;
        // width: 80%;
    }

    .numberInput input {
        background-color: #F2F6F9;
        border-radius: 4px !important;
        border: none;
    }

    .buttonn {
        box-shadow: none;
        width: 30% !important;
        background-color: #5628D2;
        padding: 5px 8px 5px 8px !important;
        color: white;
        border-radius: 4px;
    }
}

@media screen and (max-width:415px) {
    .twit .button {
        width: 100% !important;
    }

    .numberInput input {
        width: 100% !important;
    }

    .rightt {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

}

.numberInputt input {
    background-color: #F2F6F9;
    border-radius: 4px !important;
    border: none;
}

@media screen and (max-width:441px) {
    .numberInputt input {
        background-color: #F2F6F9;
        border-radius: 4px !important;
        border: none;
        // width: 200% !important;
    }

}

.policy {
    margin-top: 180px !important;

    h1 {
        color: #512DA8;
        font-family: $fontfamily;
        font-weight: 700;
    }

    h3 {
        color: #512DA8;
        font-family: $fontfamily;
        font-weight: 700;
    }
    .tbody{
        tr{
            td,th{
                border: 1px solid black;
            }
        }
    }
}

.blogPost {
    margin-top: 120px !important;

    h2,
    h4,
    h5 {
        color: #512DA8;
    }
}

.Loader {
    .rotate {
        animation: rotate360 infinite 1s linear;
    }
}

@keyframes rotate360 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

// .impact-generator-container {
//     display: flex;
//     align-items: flex-start;
//     justify-content: space-between;
//     padding: 20px;
//     border-radius: 20px;
//     background-color: #f2f1ff;
//     margin-top: 50px;
//     margin-bottom: 80px;
    
//   }

// .howitworkstext {
//     color: #929292;
//     text-align: center;
//     margin-top: 30px;
//     font-size: 22px;
// }


  
//   .impact-info {
//     max-width: 40%;
//     padding: 20px;
//   }
  
//   .impact-info h1 {
//     font-size: 28px;
//     color: #333;
//   }
  
//   .impact-info p {
//     margin: 10px 0;
//     color: #666;
//   }
  
//   .impact-buttons {
//     margin-top: 320px;
//   }
  
//   .btn {
//     padding: 10px 20px;
//     margin: 5px;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;
//   }
  
//   .demo-btn {
//     background-color: #6100FF;
//     border-radius: 20px;
//     color: white;
//     margin-right: 30px;
//   }
  
//   .paid-btn {
//     background-color: #8a6ae1;
//     border-radius: 20px;
//     color: white;
//   }
  
//   .impact-image {
//     max-width: 50%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   .impact-image2 {
//     max-width: 50%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-color:#4f31a1 ;
//     border-radius: 10px;
//   }




  
//   .impact-image img {
//     width: 100%;
//     height: auto;
//     border-radius: 8px;
//     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
//   }

//   .thirdimage {
//     width: 20px;
//     height: 500px;
//   }
  

/* Base styles for HOWITWORKS*/
.impact-generator-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.25rem;
    border-radius: 1.25rem;
    background-color: #f2f1ff;
    margin: 3rem 1rem;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    overflow: hidden; /* Prevent overflow */
}

.impact-info {
    width: 100%;
    max-width: 40%;
    padding: 1.25rem;
}

.howitworkstext {
    color: #929292;
    text-align: center;
    margin-top: 1.875rem;
    font-size: 1.375rem;
}

.impact-buttons {
    margin-top: 2rem; /* Reduced from 320px */
}

.impact-image, .impact-image2 {
    width: 100%;
    max-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure the image stays within the container */
}

.impact-image img, .impact-image2 img {
    max-width: 100%; /* Image scales with its container */
    height: auto; /* Maintain aspect ratio */
}

/* Media queries for mobile responsiveness */
@media screen and (max-width: 768px) {
    .impact-generator-container {
        flex-direction: column;
        padding: 1rem;
        margin: 2rem 0.5rem;
    }

    .impact-info {
        max-width: 100%;
        padding: 1rem;
        order: 1; /* Control the order of elements */
    }

    .impact-image, .impact-image2 {
        max-width: 100%;
        margin: 1rem 0;
        order: 2;
    }

    .howitworkstext {
        font-size: 1.125rem;
        margin-top: 1.5rem;
    }

    .impact-buttons {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .demo-btn, .paid-btn {
        width: 100%;
        margin: 0;
        padding: 0.75rem;
    }
}

/* Additional breakpoint for very small screens */
@media screen and (max-width: 480px) {
    .impact-info h1 {
        font-size: 1.5rem;
    }

    .howitworkstext {
        font-size: 1rem;
    }
}

  .articles-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: #f8f8f8;
  }
  
  /* Latest Articles Section */
  .latest-articles {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .section-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .articles-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .article-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    padding: 20px;
    text-align: left;
  }
  
  .article-image {
    background-color: #e0e0e0;
    height: 150px;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .article-content {
    padding: 10px 0;
  }
  
  .article-tag {
    display: inline-block;
    font-size: 0.8rem;
    color: #555;
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 12px;
    margin-bottom: 10px;
  }
  
  .article-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .read-more-link {
    color: #6200ee;
    font-weight: bold;
    text-decoration: none;
  }
  
  .read-more-link:hover {
    text-decoration: underline;
  }
  
  /* Subscribe Section */
  .subscribe-section {
    text-align: center;
    background-color: #f4f1ff;
    padding: 40px 20px;
    border-radius: 8px;
  }
  
  .subscribe-section h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .subscribe-section p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #666;
  }
  
  .subscribe-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .subscribe-input {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 25px;
    width: 300px;
  }
  
  .subscribe-button {
    background-color: #6200ee;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .subscribe-button:hover {
    background-color: #5a00d1;
  }
  
  .container2{
    
        box-sizing: border-box;
        color: rgb(33, 37, 41);
        display: block;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        font-size: 16px;
        font-weight: 400;
        height: 699.15px;
        line-height: 24px;
        margin-left:0px;
        margin-right: 0px;
        width: 99.5vw;
       
        text-align: center;
        text-size-adjust: 100%;
        unicode-bidi: isolate;
        
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      
  }
//   .about-us-container {
//     background-color: #f8f5ff;
//     padding: 40px 20px;
//     text-align: center;
//     height: 900px;
//     margin-top: 100px;
//   }
  
//   .about-us-title {
//     font-size: 28px;
//     font-weight: 600;
//     color: #333;
//     margin-bottom: 20px;
//   }
  
//   .about-us-purpose {
//     background-color: #fff;
//     padding: 20px;
//     border-radius: 12px;
//     margin: 10px auto;
//     max-width: 1000px;
//     height: 275px;
//     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    
//   }
  
//   .purpose-badge {
//     display: inline-block;
//     background-color: #eaeaff;
//     color: #6100FF;
//     font-weight: 500;
//     width: 168px;
//     height: 61px;
//     font-size: 28px;
//     padding: 9px 15px;
//     border-radius: 35px;
//     margin-bottom: 10px;
//   }
  
//   .about-us-purpose p {
//     font-size: 16px;
//     color: #555;
//     margin: 0;
//   }
  
//   .about-us-mission-vision {
//     display: flex;
//     justify-content: center;
//     gap: 20px;
//     margin-top: 30px;
//   }
  
//   .mission, .vision {
//     background-color: #fff;
//     padding: 20px;
//     border-radius: 12px;
//     width: 490px;
//     height: 380px;
//     text-align: center;
//     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
//   }
  
//   .mission .icon, .vision .icon {
//     font-size: 30px;
//     color: #6c63ff;
//     margin-bottom: 10px;
//   }
  
//   .mission h4, .vision h4 {
//     font-size: 18px;
//     font-weight: 600;
//     color: #333;
//     margin-bottom: 10px;
//   }
  
//   .mission p, .vision p {
//     font-size: 14px;
//     color: #555;
//     line-height: 1.5;
//   }
  
//   .mission p strong, .vision p strong {
//     display: block;
//     font-weight: 600;
//     color: #6c63ff;
//     margin-bottom: 5px;
//   }

.about-us-container {
    background-color: #f8f5ff;
    padding: 40px 20px;
    text-align: center;
    margin-top: 100px;
    position: relative;
}

.about-us-title {
    font-size: 28px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}

.about-us-purpose {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    margin: 10px auto;
    max-width: 1000px;
    height: auto; /* Allow height to adjust dynamically */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.purpose-badge {
    display: inline-block;
    background-color: #eaeaff;
    color: #6100FF;
    font-weight: 500;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 35px;
    margin-bottom: 10px;
    white-space: nowrap; /* Prevent text wrapping */
}

.about-us-purpose p {
    font-size: 18px;
    color: #555;
    margin: 0;
    line-height: 1.5;
}

.about-us-mission-vision {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    gap: 20px;
    margin-top: 30px;
}

.mission, .vision {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    flex: 1; /* Make the cards flexible */
    min-width: 280px; /* Ensure a minimum width for smaller screens */
    max-width: 480px; /* Prevent excessive growth */
    height: auto; /* Allow height to adjust dynamically */
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.mission .icon, .vision .icon {
    font-size: 30px;
    color: #6c63ff;
    margin-bottom: 10px;
}

.mission h4, .vision h4 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.mission p, .vision p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
}

.mission p strong, .vision p strong {
    display: block;
    font-weight: 600;
    color: #6c63ff;
    margin-bottom: 5px;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
    .about-us-container {
        padding: 20px 10px;
        margin-top: 80px;
    }

    .about-us-title {
        font-size: 24px;
    }

    .about-us-purpose {
        max-width: 90%; /* Reduce width for smaller screens */
        padding: 15px;
    }

    .about-us-mission-vision {
        flex-direction: column; /* Stack the mission and vision cards */
        gap: 15px;
    }

    .mission, .vision {
        max-width: 100%; /* Allow full width on smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .about-us-title {
        font-size: 20px;
    }

    .about-us-purpose p {
        font-size: 14px;
    }

    .purpose-badge {
        font-size: 16px;
        padding: 8px 15px;
    }

    .mission h4, .vision h4 {
        font-size: 18px;
    }

    .mission p, .vision p {
        font-size: 14px;
    }

    .mission p strong, .vision p strong {
        font-size: 16px;
    }
}


  .BlogPage {
    padding: 2rem 0;
    background-color: #f8f9fa;
    height: 1000px;
    min-height: 100vh;
    width: 100%;
    position: relative;  /* Added position relative */
     /* Add margin to prevent footer overlap */
    display: flex;      /* Added flex display */
    flex-direction: column; /* Stack children vertically */
}

.headerBlogPage {
    width: 100%;
    max-width: 100%;
    padding: 20px 0;
    background-color: #f8f9fa;
    flex: 1;           /* Allow header to grow */
}

.container2 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    min-height: calc(100vh - 200px); /* Minimum height accounting for footer */
}

.articles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
   gap: 50px;
    margin-left: 10px;
    margin-top: 40px;
}

.article-card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    width: 350px;
    height: 340px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    
}

.article-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.article-image {
    overflow: hidden;
}

.article-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.article-card:hover .article-image img {
    transform: scale(1.05);
}

.article-content {
    padding-left: 0px;
}

.article-tag {
    background-color: #F2F1FF;
    color: #000;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.875rem;
    display: inline-block;
    margin-bottom: 1rem;
}

.article-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #454545;
    line-height: 1.4;
}

.read-more-link {
    color: #ffc30d;
    font-weight: 500;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: color 0.2s ease;
}

.read-more-link:hover {
    color: #e6b00c;
}

/* Blog detail specific fixes */
.blog-detail-container {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    max-width: 1000px;
    margin: 0 auto 60px; /* Added bottom margin */
    width: 100%;
}

.blog-info {
    padding: 0 1rem;
}

.blog-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    line-height: 1.2;
}

.blog-metadata {
    display: flex;
    gap: 1rem;
    color: #666;
    font-size: 0.95rem;
}

.blog-metadata .author {
    font-weight: 500;
}

.blog-content {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #444;
    margin-top: 2rem;
}

.subscribe-section {
    background: #F2F1FF;
    padding: 3rem 2rem;
    border-radius: 12px;
    text-align: center;
    margin-top: 4rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.subscribe-section h2 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    color: #333;
}

.subscribe-form {
    display: flex;
    gap: 1rem;
    max-width: 500px;
    margin: 2rem auto 0;
}

.subscribe-input {
    flex: 1;
    padding: 0.75rem 1rem;
    border: 2px solid #eee;
    border-radius: 4px;
    font-size: 1rem;
}

.subscribe-button {
    background: #6100FF;
    color: #FFFFFF;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.subscribe-button:hover {
    background: #e6b00c;
}

.back-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    color: #ffffff;
    transition: transform 0.2s ease;
}

.back-button:hover {
    transform: translateX(-4px);
}

/* Mobile specific fixes */
@media (max-width: 768px) {
    .BlogPage {
        margin-bottom: 80px; /* Increased margin for mobile */
    }

    .container2 {
        min-height: calc(100vh - 250px); /* Adjusted for mobile */
        padding-bottom: 40px; /* Added padding at bottom */
    }

    .blog-detail-container {
        margin-bottom: 80px; /* Increased margin for mobile */
    }

    /* Ensure content stays within viewport */
    .articles-container {
        margin-bottom: 40px;
    }

    /* Adjust subscribe section spacing */
    .subscribe-section {
        margin-bottom: 40px;
    }
}

/* Default (Desktop) Styles */
.main-container {
    width: 99.1vw;
    background-color: #f2f1ff;
    max-width: none;
    box-sizing: border-box;
    margin-top: 100px;
    overflow-x: hidden;
  }
  
  .row-container {
    width: 100%;
    padding: 30px 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: 250px;
  }
  
  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 18px;
    max-width: 620px;
  }
  
  .text {
    margin-left: 20px;
    text-align: left;
  }
  
  h1 {
    font-size: 64px;
  }
  
  h2 {
    font-size: 60px;
  }
  
  p {
    font-size: 24px;
    color: #4C4C4C;
  }
  
  /* Reverse for second image - This is for the reversed layout */
  .reverse-reversed .image-text-container {
    flex-direction: row-reverse; /* Reverse the order of text and image */
  }
  
  .text h2 {
    font-size: 60px;
  }
  
  .text p {
    font-size: 24px;
    color: #4C4C4C;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .row-container {
      flex-direction: column;
      padding: 0 5%;
    }
  
    .image-text-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .image {
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px; /* Margin between image and text */
    }
  
    .text {
      text-align: center;
    }
  
    h2 {
      font-size: 36px;
    }
  
    p {
      font-size: 18px;
    }
  
    /* Adjust specific sections */
    .reverse-reversed .image-text-container {
      flex-direction: column; /* Stack text and image vertically on mobile */
    }
  }
  
/* Banner Image Full Width */
// .banner img {
//     width: 100%;
//     height: auto;
//     object-fit: cover; /* Ensure the image covers the entire div */
//   }
  
//   /* Position the text properly */
//   .banner-content {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     z-index: 1;
//     color: black;
//     width: 100%;
//     text-align: center;
//   }
  
//   button.button {
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     padding: 10px 20px;
//     background-color: white;
//     color: black;
//     border-radius: 50px;
//     box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
//     font-weight: bold;
//     transition: all 0.3s ease;
//   }
  
//   button.button img {
//     margin-left: 10px;
//   }
  
//   /* Responsive Styles */
//   @media (max-width: 480px) {
//     .banner-content {
//       top: 40%; /* Adjust position */
//     }
  
//     h1 {
//       font-size: 24px;
//     }
  
//     p {
//       font-size: 14px;
//     }
  
//     button.button {
//       width: 200px;
//       height: 50px;
//       font-size: 14px;
//     }
//   }
  
//   @media (max-width: 768px) {
//     .banner-content {
//       top: 45%;
//     }
  
//     h1 {
//       font-size: 36px;
//     }
  
//     p {
//       font-size: 16px;
//     }
  
//     button.button {
//       width: 300px;
//       height: 60px;
//       font-size: 16px;
//     }
//   }
  
//   @media (min-width: 769px) {
//     .banner-content {
//       top: 50%;
//     }
  
//     h1 {
//       font-size: 48px;
//     }
  
//     p {
//       font-size: 18px;
//     }
  
//     button.button {
//       width: 383px;
//       height: 70px;
//       font-size: 18px;
//     }
//   }
  
/* Banner Image Full Width */
/* Banner Image Full Width */
/* Banner Image Full Width */
// .banner img {
//     width: 100%;
//     height: auto;
//     object-fit: cover; /* Ensure the image covers the entire div */
//   }
  
//   /* Position the text properly */
//   .banner-content {
//     position: absolute;
//     top: 0;
//     left: 50%;
//     transform: translateX(-50%);
//     z-index: 1;
//     color: black;
//     width: 100%;
//     text-align: center;
//   }
  
//   button.button {
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     padding: 10px 20px;
//     background-color: white;
//     color: black;
//     border-radius: 50px;
//     box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
//     font-weight: bold;
//     transition: all 0.3s ease;
//   }
  
//   button.button img {
//     margin-left: 10px;
//     width: 20px;
//     height: auto;
//   }
  
//   /* Responsive Styles */
//   @media (max-width: 480px) {
//     .banner-content {
//       top: 20%; /* Adjusted position for mobile */
//     }
  
//     h1 {
//       font-size: 24px;
//     }
  
//     p {
//       font-size: 14px;
//     }
  
//     button.button {
//       width: 200px;
//       height: 50px;
//       font-size: 14px;
//     }
//   }
  
//   @media (max-width: 768px) {
//     .banner-content {
//       top: 30%; /* Adjusted position for tablets */
//     }
  
//     h1 {
//       font-size: 36px;
//     }
  
//     p {
//       font-size: 16px;
//     }
  
//     button.button {
//       width: 300px;
//       height: 60px;
//       font-size: 16px;
//     }
//   }
  
//   @media (min-width: 769px) {
//     .banner-content {
//       top: 40%; /* Adjusted position for desktop */
//     }
  
//     h1 {
//       font-size: 48px;
//     }
  
//     p {
//       font-size: 18px;
//     }
  
//     button.button {
//       width: 383px;
//       height: 70px;
//       font-size: 18px;
//     }
//   }
  
  
  /* Ensure the banner image is responsive */
.banner img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* Position the text properly */
.banner-content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    color: black;
    width: 100%;
    text-align: center;
}

/* Button container is fixed at the bottom of the image */
.button-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 30px; /* Adjust the margin from the bottom */
}

/* Button Styles */
button.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: white;
    color: black;
    border-radius: 50px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    transition: all 0.3s ease;
}

button.button img {
    margin-left: 10px;
    width: 20px;
    height: auto;
}

/* Responsive Styles */
@media (max-width: 480px) {

    .banner-content h1.mb-0 {
        margin-top: 0px; /* Default margin for mobile */
    }

    .button mx-auto{
        width: '150px'
    }

    .banner-content {
        top: 20%; /* Adjusted position for mobile */
    }

    h1 {
        
        font-size: 24px;
    }

    p {
        font-size: 14px;
    }

    button.button {
        white-space: nowrap; /* Prevents text from wrapping */
        overflow: hidden; /* Hides overflow if text is too long */
        text-overflow: ellipsis; /* Adds "..." if text overflows */
    }

    button.button img {
        margin-left: 5px;   
    }
}

@media (max-width: 768px) {
    .banner-content {
        top: 30%; /* Adjusted position for tablets */
    }

    h1 {
        font-size: 36px;
    }

    p {
        font-size: 16px;
    }

    button.button {
        width: 300px;
        height: 60px;
        font-size: 16px;
    }
}

@media (min-width: 769px) {

  
    .banner-content {
        top: 10%; /* Adjusted position for desktop */
    }

    h1 {
        font-size: 48px;
    }

    p {
        font-size: 18px;
    }

    button.button {
        width: auto; /* Allow button to auto adjust width */
        max-width: 90%; /* Limit maximum width for smaller devices */
        font-size: 14px; /* Keep text size consistent */
    }
}

/* Navbar Area Styling */
/* Navbar Area Styling */
.navbar-area {
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    position: sticky;
    top: 0;
    padding: 10px 0;

    .container-fluid {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-brand {
        display: flex;
        align-items: center;
        text-decoration: none;

        img {
            height: 40px;
        }

        span {
            font-size: 20px;
            font-weight: bold;
            color: #333;
        }
    }

    .nav {
        display: flex;
        align-items: center;
        transition: transform 0.3s ease-out;

        .nav-item {
            list-style: none;

            .links {
                text-decoration: none;
                color: #333;
                padding: 10px 15px;
                transition: color 0.3s, border-bottom 0.3s;

                &:hover {
                    color: #007bff;
                }

                &.active {
                    color: #007bff;
                    border-bottom: 2px solid #007bff;
                }
            }
        }

        &.show-nav {
            display: block; /* Make nav visible when hamburger is open */
        }
    }

    .btn {
        display: flex;
        align-items: center;
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 8px 16px;
        border-radius: 5px;
        font-size: 14px;
        transition: background-color 0.3s;

        img {
            margin-left: 8px;
            width: 16px;
        }

        &:hover {
            background-color: #0056b3;
        }
    }

    .nav-toggler-icon {
        display: none;
        cursor: pointer;

        .hamIcon {
            font-size: 24px;
        }
    }

    /* Sidebar Styles */
    .custom-sidebar {
        position: fixed;
        top: 0;
        right: -100%;
        width: 250px;
        height: 100vh;
        background-color: #ffffff;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
        transition: right 0.3s ease;
        z-index: 100;

        &.show {
            right: 0;
        }

        .close-btn-wrapper {
            display: flex;
            justify-content: flex-end;
            padding: 10px;

            .close-icon {
                font-size: 24px;
                cursor: pointer;
                color: #333;
            }
        }

        .sidebar-list {
            list-style: none;
            padding: 0;

            li {
                margin: 10px 0;

                a {
                    text-decoration: none;
                    color: #333;
                    font-size: 16px;
                    padding: 10px;
                    display: block;
                    transition: color 0.3s;

                    &:hover {
                        color: #007bff;
                    }
                }
            }
        }
    }

    /* Responsive Styles */
    @media (max-width: 768px) {
        .nav {
            display: none; /* Hide nav links by default */
        }

        .btn {
            display: none; /* Hide the button on small screens */
        }

        .nav-toggler-icon {
            display: block; /* Show hamburger icon on small screens */
        }

        .custom-sidebar {
            .sidebar-list li a {
                font-size: 18px; /* Make sidebar links more prominent for smaller screens */
            }
        }
    }
}





  
// /* Default (Desktop) Styles */
// .main-container {
//     width: 100vw;
//     background-color: #f2f1ff;
//     max-width: none;
//     box-sizing: border-box;
//     margin-top: 100px;
//     overflow-x: hidden;
//   }
  
//   .row-container {
//     width: 100%;
//     padding: 30px 5%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
  
//   .image-text-container {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     flex: 1;
//     min-width: 250px;
//   }
  
//   .image {
//     width: 100%;
//     height: auto;
//     object-fit: cover;
//     border-radius: 18px;
//     max-width: 620px;
//   }
  
//   .text {
//     margin-left: 20px;
//     text-align: left;
//   }
  
//   h1 {
//     font-size: 64px;
//   }
  
//   h2 {
//     font-size: 60px;
//   }
  
//   p {
//     font-size: 24px;
//     color: #4C4C4C;
//   }
  
//   /* Reverse for second image */
//   .reverse {
//     flex-direction: row-reverse;
//   }
  
//   .text h2 {
//     font-size: 60px;
//   }
  
//   .text p {
//     font-size: 24px;
//     color: #4C4C4C;
//   }
  
//   /* Mobile Styles */
//   @media (max-width: 768px) {
//     .row-container {
//       flex-direction: column;
//       padding: 0 5%;
//     }
  
//     .image-text-container {
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//     }
  
//     .image {
//       width: 100%;
//       max-width: 100%;
//       margin-bottom: 20px; /* Margin between image and text */
//     }
  
//     .text {
//       text-align: center;
//     }
  
//     h2 {
//       font-size: 36px;
//     }
  
//     p {
//       font-size: 18px;
//     }
  
//     /* Adjust specific sections */
//     .reverse {
//       flex-direction: column; /* Stack text and image vertically on mobile */
//     }
//   }
  



// @media(max-width: 768px) {
//     .row {
//       display: block; /* Stack images and text vertically on smaller screens */
//     }
  
//     .row img {
//       width: 100%; /* Full-width images */
//       max-width: none; /* Remove any width restrictions */
//     }
  
//     .row div {
//       text-align: center; /* Center the text */
//       padding: 10px; /* Add some padding */
//     }
  
//     h2 {
//       font-size: 36px; /* Adjust font size for mobile */
//     }
  
//     p {
//       font-size: 18px; /* Adjust paragraph font size for mobile */
//     }
//   }


.BlogPage {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

.container2 {
    width: 100%;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Articles Grid */
.articles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px 0;
}

.article-card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s;
    width: 100%;
}

.article-card:hover {
    transform: translateY(-5px);
}

.article-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.article-content {
    padding: 15px;
}

.section-heading {
    font-size: clamp(32px, 5vw, 64px);
    margin-bottom: 30px;
}

/* Subscribe Section */
.subscribe-section {
    padding: 40px 20px;
    margin: 40px 0;
    background: #f5f5f5;
    border-radius: 8px;
    text-align: center;
}

.subscribe-form {
    display: flex;
    gap: 10px;
    max-width: 600px;
    margin: 20px auto;
}

.subscribe-input {
    flex: 1;
    min-width: 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.subscribe-button {
    padding: 10px 20px;
    background: #6100ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Blog Detail View */
.blog-detail-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.blog-detail-content img {
    max-width: 100%;
    height: auto;
}

.blog-info {
    margin-top: 20px;
}

.blog-title {
    font-size: clamp(24px, 4vw, 36px);
}

/* Cards Component */
.cardbox {
    width: 100%;
}

.cardss {
    width: 100%;
    background: white;
}

.imgs img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

/* Media Queries */
@media (max-width: 768px) {
    .subscribe-form {
        flex-direction: column;
    }
    
    .subscribe-input,
    .subscribe-button {
        width: 100%;
    }
    
    .blog-detail-container {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .articles-grid {
        grid-template-columns: 1fr;
    }
    
    .article-card {
        margin-bottom: 20px;
    }
    
    .section-heading {
        text-align: center;
    }
}



// #careers-h1 {
//     text-align: center;
//     font-size: 64px; /* Default for large screens */
//     font-family: 'DM Sans';
//     font-weight: 900;
// }

/* For tablets and small desktops (max width: 1024px) */
// @media (max-width: 1024px) {
//     #careers-h1 {
//         font-size: 48px;
//     }
// }

// /* For landscape tablets and large phones (max width: 768px) */
// @media (max-width: 768px) {
//     #careers-h1 {
//         font-size: 36px;
//         text-align: start; /* Left-align for smaller screens */
//     }
// }

// /* For portrait phones and smaller devices (max width: 480px) */
// @media (max-width: 480px) {
//     #careers-h1 {
//         font-size: 28px;
//         text-align: start; /* Left-align for smaller screens */
//     }
// }

// /* For very small screens (max width: 320px) */
// @media (max-width: 320px) {
//     #careers-h1 {
//         font-size: 24px;
//         text-align: start; /* Left-align for smaller screens */
//     }
// }
